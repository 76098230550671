<template>
    <div style="padding: 10px; border: 1px solid #eee;">
		<span slot="label">
			Hide Dots
			<a-tooltip overlayClassName="change-tooltip-color" title="Choose whether to hide or show status dots.">
				<a-icon type="question-circle-o" style="color: var(--orange);" />
			</a-tooltip>
		</span>
		<div style="line-height: 1; margin-bottom: 1em;" v-if="siteplanObj">
			<a-switch v-model="siteplanObj.hideDots" />
		</div>

		<template v-if="!siteplanObj.hideDots">
			<span slot="label">
				Pulsate Dots
			</span>
			<div style="line-height: 1; margin-bottom: 1em;">
				<a-switch v-model="siteplanObj.pulsateDots" />
			</div>
		</template>

		<template v-if="siteplanObj.hideDots">
			<span slot="label">
				Remove Status Color Overlay
				<a-tooltip overlayClassName="change-tooltip-color" title="Choose whether to hide or show the status color blocks.">
					<a-icon type="question-circle-o" style="color: var(--orange);" />
				</a-tooltip>
			</span>
			<div style="line-height: 1; margin-bottom: 1em;">
				<a-switch v-model="siteplanObj.hideStatus" />
			</div>
		</template>

		<template v-if="siteplanObj.hideDots && !siteplanObj.hideStatus">
			<span slot="label">
				Adjust Lot # Font Size
			</span>
			<div class="w-full dF" style="line-height: 1; margin-bottom: 1em;">
				<a-slider style="flex: 1; height: auto;" v-model="siteplanObj.fontSize" :min="1" :max="10" />
			</div>
		</template>
	</div>
</template>

<script>
export default {
    props:{
        value:{
            type:Object,
            default: () => ({})
        },
    },
    data(){
        return {
            siteplanObj:{}
        }
    },
    computed:{

    },
    watch:{
        siteplanObj:{
            handler(val,oldVal){
                return this.$emit('input', val)
            },
			deep:true
        }
    },
	methods:{

	},
    async created(){
		if (this.value){
			this.siteplanObj = this.value
		}
    },
    mounted(){
        // if (!this.value) this.instance = this.instances[0].value
    }
}
</script>

<style scoped lang="scss">
    .button-editor-container{
        padding:10px;
        border:1px solid #eee;

    }
</style>
